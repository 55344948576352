<template>
 <div class="nk-block">
        <div class="card card-bordered">
            <div class="card-inner-group">
         <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="Details">
         <leadForm :leadId="leadId"></leadForm>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Notes" force-render>
        <div v-if="leadId" style="padding: 20px;">
           <notes  
                :id="leadId"
                :entityName="'lead'"
                :getEndpoint="'/leads/Get_Lead_Notes/' + leadId"
                :saveEndpoint="'/leads/Save_Lead_Note/'"
                :deleteEndpoint="'/leads/Delete_Lead_Note/'"
            ></notes>
        </div>
        <div v-else>
          <p class="text-center">Please save the lead before adding notes.</p>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Attachments">
          <div v-if="leadId">
                    <attachments  
                      :id="leadId"
                      :entityName="'lead'"
                      :getEndpoint="'/leads/Get_Lead_Attachments/' + leadId" ></attachments>
                    </div>
                    <div v-else>
                      <p class="text-center">Please save the lead before adding attachments.</p>
                    </div>
      </a-tab-pane>
    </a-tabs>  
  </div>
    </div>
      </div>
</template>

<script>
import leadForm from '@/components/leads/lead/form.vue'
import notes from '@/components/generic/notes'
import attachments from '@/components/generic/attachments'
export default {
  data () {
    return {
      leadId: null
    }
  },
  components: { leadForm, notes, attachments },
  created(){
    this.getView()
    if (this.$route.query.leadId != null){
      this.leadId = parseInt(this.$route.query.leadId)
    }
  },
  methods: {
    getView() {
        this.$http.get('/ViewAccess/lead')
        .then(() => {
        })
        .catch(() => {
        })
    },
  }
}
</script>

<style>

</style>