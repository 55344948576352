<template>
    <div class="row g-gs">
        <div class="col-lg-12">
                <div class="card-inner">
                    <div class="card-head">
                        <h5 class="card-title"><em class="icon ni ni-user"/> Lead Details</h5>
                        <button  v-on:click="saveLead()" class="btn btn-sm btn-success float-right"><em class="icon ni ni-save mr-1"></em> Save</button>
                    </div>
                    <div class='row'>
                        <div class="col-md-6">
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="leadOwner"><em class="icon ni ni-user"/> Lead Owner</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select class="invisibox" v-model="lead.leadOwnerId"
                                                show-search
                                                placeholder="Select a user"
                                                option-filter-prop="children"
                                                :filter-option="filterOption"
                                                style="width: 100%" :class="{'is-error' : $v.lead.leadOwnerId.$invalid }">
                                            <a-select-option :value="null" > Not selected </a-select-option>
                                                <a-select-option v-for="user in users" v-bind:key="user.id">
                                                    {{ user.firstName + ' ' + user.lastName }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="firstName"><em class="icon ni ni-user"/> First Name</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="First Name" :class="{'is-error' : $v.lead.firstName.$invalid }" type="text" v-model="lead.firstName" class="invisibox" id="firstName" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="lastName"><em class="icon ni ni-user"/> Last Name</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Last Name" type="text" :class="{'is-error' : $v.lead.lastName.$invalid }" v-model="lead.lastName" class="invisibox" id="lastName" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="jobTitle"><em class="icon ni ni-user"/> Job Title</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Job Title" type="text" v-model="lead.jobTitle" class="invisibox" id="jobTitle"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="tel"><em class="icon ni ni-call"/> Phone</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Phone Number" type="text" v-model="lead.phoneNumber" class="invisibox" id="tel"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="mobile"><em class="icon ni ni-mobile"/> Mobile</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Mobile" type="text" v-model="lead.mobile" class="invisibox" id="mobile"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="fax"><em class="icon ni ni-printer"/> Fax</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Fax" type="text" v-model="lead.fax" class="invisibox" id="fax"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="email"><em class="icon ni ni-mail"/> Email</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Email" type="text" v-model="lead.email" class="invisibox" id="email"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="companyName"><em class="icon ni ni-building"/> Company Name</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Company Name" type="text" :class="{'is-error' : $v.lead.companyName.$invalid }" v-model="lead.companyName" class="invisibox" id="companyName"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="companyWebsite"><em class="icon ni ni-globe"/> Company Website</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Company Website" type="text" v-model="lead.websiteUri" class="invisibox" id="companyWebsite"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right"><em class="icon ni ni-notes-alt"/> Industy Type</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select  style="width: 100%" placeholder="Select Industry Type"  class="invisibox" v-model="lead.industryTypeId">
                                                <a-select-option :value="null" > Not selected </a-select-option>
                                                <a-select-option v-for="industryType in industryTypes" v-bind:key="industryType.id">
                                                    {{ industryType.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right"><em class="icon ni ni-globe"/> Source</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select  style="width: 100%" placeholder="Select Source" class="invisibox" v-model="lead.sourceId">
                                                <a-select-option :value="null" > Not selected </a-select-option>
                                                <a-select-option v-for="source in sources" v-bind:key="source.id">
                                                    {{ source.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                            <label class="form-label float-right"><em class="icon ni ni-location"/> Region</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select  style="width: 100%" placeholder="Select Region" class="invisibox" v-model="lead.regionId">
                                                <a-select-option :value="null" > Not selected </a-select-option>
                                                <a-select-option v-for="region in regions" v-bind:key="region.id">
                                                    {{ region.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right"><em class="icon ni ni-notes-alt"/> Status</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select :class="{'is-error' : $v.lead.statusId.$invalid }" style="width: 100%" placeholder="Select Status" class="invisibox" v-model="lead.statusId">
                                                <a-select-option :value="null" > Not selected </a-select-option>
                                                <a-select-option v-for="status in statuses" v-bind:key="status.id">
                                                    {{ status.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="preview-hr">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="companyWebsite"><em class="icon ni ni-map-pin"/> Address</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                               <a-input placeholder="Line 1" type="text" v-model="lead.billingLine1" class="invisibox" :class="{'is-error' : $v.lead.billingLine1.$invalid }"/>
                                               <a-input placeholder="Line 2" type="text" v-model="lead.billingStreet" class="invisibox address"/>
                                               <a-input placeholder="Town" type="text" v-model="lead.billingProvince" class="invisibox address" :class="{'is-error' : $v.lead.billingProvince.$invalid }"/>
                                               <a-input placeholder="City" type="text" v-model="lead.billingCity" class="invisibox address" :class="{'is-error' : $v.lead.billingCity.$invalid }"/>
                                               <a-input placeholder="Post Code" type="text" v-model="lead.billingPostalCode" class="invisibox address" :class="{'is-error' : $v.lead.billingPostalCode.$invalid }"/>
                                               <a-input placeholder="Country" type="text" v-model="lead.billingCountry" class="invisibox address"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button  v-on:click="saveLead()" class="btn btn-sm btn-success float-right"><em class="icon ni ni-save mr-1"></em> Save</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    props: {
        leadId: Number,
    },
    validations: {
        lead: {
            leadOwnerId: {
                required
            },
            firstName: {
                required
            },
            lastName: {
                required
            },
            companyName: {
                required
            },
            billingLine1: {
                required
            },
            billingProvince: {
                required
            },
            billingCity: {
                required
            },
            billingPostalCode: {
                required
            },
            statusId: {
                required
            }
        }
    },
    data(){
        return {
            lead: {},
            dateFormat: 'DD/MM/YYYY',
            industryTypes: [],
            sources: [],
            paymentMethods: [],
            users: [],
            statuses: [],
            regions: [],
            filterOption: null
        }
    },
    created() {
        this.getLists()
        if (this.leadId != null) {
            this.getLead(this.leadId)
        } else {
            this.getLead()
        }
    },
    methods: {
        saveLead () {
            if (this.lead.id != null)  {
                if (this.$v.lead.$invalid) {
                    this.$message.error('Please check for any form errors.')
                } else {
                    this.updateLead()
                }
            } 
            else {
                if (this.$v.lead.$invalid) {
                    this.$message.error('Please check for any form errors.')
                } else {
                    this.saveNewLead()
                }
            }
        },
        saveNewLead() {
            this.$http.post('/leads/Add_Lead', this.lead)
            .then(() => {
                this.$message.success('Lead Created')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateLead() {
            this.$http.post('/leads/Update_Lead', this.lead)
            .then(() => {
                this.$message.success('Lead Updated')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getLead(id) {
             let uri  =  id ? ('/leads/get_Lead/' + id) : '/leads/get_Lead/'
            this.$http.get(uri)
            .then((response) => {
                this.lead = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getLists(){
            this.$http.get('/lists/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/industryTypes')
            .then((response) => {
                this.industryTypes = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/source')
            .then((response) => {
                this.sources = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/regions')
            .then((response) => {
                this.regions = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/leadStatus')
            .then((response) => {
                this.statuses = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
    }
}
</script>

<style scoped>
.form-label {
    margin-top: 6px
}
.address {
    margin-top: 10px;
}
</style>